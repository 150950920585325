import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Login = lazy(() => import('./login/Login'));
const ForgetPassword = lazy(() => import('./login/ForgetPassword'));
const ChangePassword = lazy(() => import('./login/ChangePassword'));

const Maintenance = lazy(() => import('./login/Maintenance'));

const NewListProductType = lazy(() => import('./product/productList'));
const AddProduct = lazy(() => import('./product/AddProduct'));
const EditProduct = lazy(() => import('./product/EditProduct'));

const NewListTeam = lazy(() => import('./team/teamList'));
const AddTeam = lazy(() => import('./team/AddTeam'));
const EditTeam = lazy(() => import('./team/EditTeaam'));

const NewListEstimate = lazy(() => import('./estimate/estimateList'));
const AddEstimate = lazy(() => import('./estimate/AddEstimate'));
const ViewEstimate = lazy(() => import('./estimate/ViewEstimate'));

const NewListPruchaseGranite = lazy(() => import('./purchase_granite/purchaseGranite'));
const AddPurchaseGranite = lazy(() => import('./purchase_granite/AddPurchaseGranite'));
const EditPurchaseGranite = lazy(() => import('./purchase_granite/EditPurchaseGranite'));
const NewListPruchaseTiles = lazy(() => import('./purchase_tiles/purchaseTiles'));
const AddPurchaseTiles = lazy(() => import('./purchase_tiles/AddPurchaseTiles'));
const EditPurchaseTiles = lazy(() => import('./purchase_tiles/EditPurchaseTiles'));
const ViewPurchase = lazy(() => import('./purchase_granite/ViewPurchase'));

const NewListSalesGranite = lazy(() => import('./sales/salesList'));
const AddSales = lazy(() => import('./sales/AddSales'));
const ViewSales = lazy(() => import('./sales/ViewSales'));
const AddSalesDirect = lazy(() => import('./sales/AddSalesDirect'));
const EditSalesDirect = lazy(() => import('./sales/EditSalesDirect'));

const AddDailyBook = lazy(() => import('./dayBook/AddDailyBook'));
const EditDailyBook = lazy(() => import('./dayBook/EditDailyBook'));
const NewListDayBookReceived = lazy(() => import('./dayBook/dayBookReceivedList'));
const EditDayBookReceived = lazy(() => import('./dayBook/EditdayBookReceived'));
const NewListDayBookPaid = lazy(() => import('./dayBook/dayBookPaidList'));
const EditDayBookPaid = lazy(() => import('./dayBook/EditdayBookPaid'));

const DayBookReport = lazy(() => import('./reports/dayBook/dayBookReport'));
const TrialBalanceForm = lazy(() => import('./reports/trialBalance/trialBalanceForm'));
const TrialBalanceReport = lazy(() => import('./reports/trialBalance/trialBalanceReport'));
const LedgerForm = lazy(() => import('./reports/ledger/ledgerForm'));
const LedgerReport = lazy(() => import('./reports/ledger/ledgerReport'));
const StocksForm = lazy(() => import('./reports/stocks/stocksForm'));
const StocksReport = lazy(() => import('./reports/stocks/stocksReport'));

const Error404 = lazy(() => import('./error/Error404'));


class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/" component={ Login } />
          <Route exact path="/login" component={ Login } />
          <Route  path="/forget-password" component={ ForgetPassword } />
          <Route  path="/change-password" component={ ChangePassword } />
         
          <Route  path="/maintenance" component={ Maintenance } />

          <Route  path="/product" component={ NewListProductType } />
          <Route  path="/addproduct" component={ AddProduct } />
          <Route  path="/editproduct/:id" component={ EditProduct } />

          <Route  path="/user" component={ NewListTeam } />
          <Route  path="/adduser" component={ AddTeam } />
          <Route  path="/edituser/:id" component={ EditTeam } />

          <Route  path="/estimateList" component={ NewListEstimate } />
          <Route  path="/addestimate" component={ AddEstimate } />
          <Route  path="/viewestimate/:id" component={ ViewEstimate } />

          <Route  path="/purchaseGranite" component={ NewListPruchaseGranite } />
          <Route  path="/addpurchaseGranite" component={ AddPurchaseGranite } />
          <Route  path="/editpurchaseGranite/:id" component={ EditPurchaseGranite } />
          <Route  path="/purchaseTiles" component={ NewListPruchaseTiles } />
          <Route  path="/addpurchaseTiles" component={ AddPurchaseTiles } />
          <Route  path="/editpurchaseTiles/:id" component={ EditPurchaseTiles } />
          <Route  path="/viewpurchase/:id" component={ ViewPurchase } />

          <Route  path="/saleList" component={ NewListSalesGranite } />
          <Route  path="/addsale/:id" component={ AddSales } />
          <Route  path="/viewsale/:id" component={ ViewSales } />
          <Route  path="/addsaledirect" component={ AddSalesDirect } />
          <Route  path="/editsalesDirect/:id" component={ EditSalesDirect } />

          <Route  path="/dayBook" component={ AddDailyBook } />
          <Route  path="/editdayBook/:id" component={ EditDailyBook } />
          <Route  path="/dayBookReceived" component={ NewListDayBookReceived } />
          <Route  path="/editReceived/:id" component={ EditDayBookReceived } />
          <Route  path="/dayBookPaid" component={ NewListDayBookPaid } />
          <Route  path="/editPaid/:id" component={ EditDayBookPaid } />
          
          <Route  path="/dayBookReport" component={ DayBookReport } />
          <Route  path="/trialBalanceForm" component={ TrialBalanceForm } />
          <Route  path="/trialBalanceReport" component={ TrialBalanceReport } />
          <Route  path="/ledgerForm" component={ LedgerForm } />
          <Route  path="/ledgerReport" component={ LedgerReport } />
          <Route  path="/stocksForm" component={ StocksForm } />
          <Route  path="/stocksReport" component={ StocksReport } />

          <Route  path="*" component={ Error404 } />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;