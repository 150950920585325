import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/maps', state: 'mapsMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
      {path:'/general-pages', state: 'generalPagesMenuOpen'},
      {path:'/ecommerce', state: 'ecommercePagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));
 
  }

  render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        {localStorage.getItem("user_type_id") == '2' ? 
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a href="!#" className="nav-link" onClick={evt =>evt.preventDefault()}>
              <div className="nav-profile-image">
                <img src={ require("../../assets/images/faces/face1.jpg") } alt="profile" />
                <span className="login-status online"></span>
              </div>
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2"><Trans>{localStorage.getItem('full_name')}</Trans></span>
                <span className="text-secondary text-small"><Trans></Trans></span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li>
          
          <li className={ this.isPathActive('/product') ? 'nav-item active' : this.isPathActive('/addproduct') ? 'nav-item active' : this.isPathActive('/editproduct') ? 'nav-item active' : this.isPathActive('/user') ? 'nav-item active' : this.isPathActive('/adduser') ? 'nav-item active' : this.isPathActive('/edituser') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Master</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-view-list menu-icon"></i>
            </div>
            <Collapse in={ this.state.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/product') ? 'nav-link active' : this.isPathActive('/addproduct') ? 'nav-link active' : this.isPathActive('/editproduct') ? 'nav-link active' : 'nav-link' } to="/product"><Trans>Product</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/user') ? 'nav-link active' : this.isPathActive('/adduser') ? 'nav-link active' : this.isPathActive('/edituser') ? 'nav-link active' : 'nav-link' } to="/user"><Trans>User</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/estimateList') ? 'nav-item active' : this.isPathActive('/addestimate') ? 'nav-item active' : this.isPathActive('/viewestimate') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/estimateList">
              <span className="menu-title"><Trans>Estimate</Trans></span>
              <i className="mdi mdi-library-books menu-icon"></i>
            </Link>
          </li>
          
          <li className={ this.isPathActive('/purchaseGranite') ? 'nav-item active' : this.isPathActive('/addpurchaseGranite') ? 'nav-item active' : this.isPathActive('/editpurchaseGranite') ? 'nav-item active' : this.isPathActive('/purchaseTiles') ? 'nav-item active' : this.isPathActive('/addpurchaseTiles') ? 'nav-item active' : this.isPathActive('/editpurchaseTiles') ? 'nav-item active' : this.isPathActive('/viewpurchase') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.basicUiMenuOpens ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpens') } data-toggle="collapse">
              <span className="menu-title"><Trans>Purchase</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-cart-outline menu-icon"></i>
            </div>
            <Collapse in={ this.state.basicUiMenuOpens }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/purchaseGranite') ? 'nav-link active' : this.isPathActive('/addpurchaseGranite') ? 'nav-link active' : this.isPathActive('/editpurchaseGranite') ? 'nav-link active' : this.isPathActive('/viewpurchase') ? 'nav-link active' : 'nav-link' } to="/purchaseGranite"><Trans>Purchase Granite</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/purchaseTiles') ? 'nav-link active' : this.isPathActive('/addpurchaseTiles') ? 'nav-link active' : this.isPathActive('/editpurchaseTiles') ? 'nav-link active' : 'nav-link' } to="/purchaseTiles"><Trans>Purchase Tiles</Trans></Link></li>
              </ul>
            </Collapse>
          </li>

          <li className={ this.isPathActive('/saleList') ? 'nav-item active' : this.isPathActive('/addsale') ? 'nav-item active' : this.isPathActive('/viewsale') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/saleList">
              <span className="menu-title"><Trans>Sales</Trans></span>
              <i className="mdi mdi-currency-inr menu-icon"></i>
            </Link>
          </li>
          <li className={ this.isPathActive('/dayBook') ? 'nav-item active' : this.isPathActive('/dayBookReceived') ? 'nav-item active' : this.isPathActive('/dayBookPaid') ? 'nav-item active' : this.isPathActive('/editReceived') ? 'nav-item active': this.isPathActive('/editPaid') ? 'nav-item active' :  'nav-item' }>
            <div className={ this.state.errorPagesMenuOpen4 ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen4') } data-toggle="collapse">
              <span className="menu-title"><Trans>Day Book</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-book-open-variant menu-icon"></i>
            </div>
            <Collapse in={ this.state.errorPagesMenuOpen4 }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/dayBook') ? 'nav-link active' :  'nav-link' } to="/dayBook">Day Book</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/dayBookReceived') ? 'nav-link active' : this.isPathActive('/editReceived') ? 'nav-link active' : 'nav-link' } to="/dayBookReceived">Credit</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/dayBookPaid') ? 'nav-link active' :  this.isPathActive('/editPaid') ? 'nav-link active' :  'nav-link' } to="/dayBookPaid">Debit</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/dayBookReport') ? 'nav-item active' : this.isPathActive('/trialBalanceForm') ? 'nav-item active' : this.isPathActive('/trialBalanceReport') ? 'nav-item active' : this.isPathActive('/ledgerForm') ? 'nav-item active': this.isPathActive('/ledgerReport') ? 'nav-item active' : this.isPathActive('/stocksForm') ? 'nav-item active' : this.isPathActive('/stocksReport') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Reports</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-file menu-icon"></i>
            </div>
            <Collapse in={ this.state.errorPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/ledgerForm') ? 'nav-link active' : this.isPathActive('/ledgerReport') ? 'nav-link active' : 'nav-link' } to="/ledgerForm">Ledger</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/trialBalanceForm') ? 'nav-link active' : this.isPathActive('/trialBalanceReport') ? 'nav-link active' : 'nav-link' } to="/trialBalanceForm">Trial Balance</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/dayBookReport') ? 'nav-link active' :  'nav-link' } to="/dayBookReport">Day Book</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/stocksForm') ? 'nav-link active' : this.isPathActive('/stocksReport') ? 'nav-link active' : 'nav-link' } to="/stocksForm">Stocks</Link></li>
              </ul>
            </Collapse>
          </li>
        </ul>
       : 
       <ul className="nav">
        <li className="nav-item nav-profile">
          <a href="!#" className="nav-link" onClick={evt =>evt.preventDefault()}>
            <div className="nav-profile-image">
              <img src={ require("../../assets/images/faces/face1.jpg") } alt="profile" />
              <span className="login-status online"></span>
            </div>
            <div className="nav-profile-text">
              <span className="font-weight-bold mb-2"><Trans>{localStorage.getItem('full_name')}</Trans></span>
              <span className="text-secondary text-small"><Trans></Trans></span>
            </div>
            <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
          </a>
        </li>
        <li className={ this.isPathActive('/estimateList') ? 'nav-item active' : this.isPathActive('/addestimate') ? 'nav-item active' : this.isPathActive('/viewestimate') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/estimateList">
              <span className="menu-title"><Trans>Estimate</Trans></span>
              <i className="mdi mdi-library-books menu-icon"></i>
            </Link>
          </li>
       </ul>
       }
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);